import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button/index.js'
import CircularProgress from '@mui/material/CircularProgress/index.js'
import Radio from '@mui/material/Radio/index.js'
import RadioGroup from '@mui/material/RadioGroup/index.js'
import FormControlLabel from '@mui/material/FormControlLabel/index.js'

import FlashMessage from '../../components/FlashMessage.jsx'
import RmTextField from './RmTextField.jsx'
import Box from '@mui/material/Box/index.js'
import RmDatePicker from './RmDatePicker.jsx'
import RmTooltip from './RmTooltip.jsx'
import EditableInteger from '../remaker/EditableInteger.jsx'
import Signature from './Signature.jsx'

import API from '../../API.js'
import { debounced } from '../../../shared-universal/Utils.js'
import { appFetch, useAbortSignal } from '../../AppFetch.js'
import EditableOrganizationNumber from './EditableOrganizationNumber.jsx'
import { getUrlParam } from '../../UrlUtils.js'
import { stringToBoolean } from '../../../shared-universal/Utils.js'
import RmComboBox from './RmComboBox.jsx'
import { selectableAuditorRoles } from '../../../shared-universal/SignatureRoles.js'

const setForetradareCount = (annualReportId, newForetradareCount, abortSignal) => {
  return appFetch('/api/up/set-foretradare-count', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({
      annualReportId,
      newForetradareCount,
    }),
  })
}

const setFYearCount = (annualReportId, newFYearCount, abortSignal) => {
  return appFetch('/api/up/set-fyear-count', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({
      annualReportId,
      newFYearCount,
    }),
  })
}

const setFYear = debounced(500, (financialYearId, fYearAttributes, abortSignal) => {
  return appFetch('/api/up/save-fyear', {
    signal: abortSignal,
    method: 'POST',
    body: JSON.stringify({ financialYearId, ...fYearAttributes }),
  })
})

const ForetagsinformationEditor = ({ annualReport, onChange, isApproved }) => {
  const abortSignal = useAbortSignal()
  const [hamtaForetagsUppgifterErrorMessage, setHamtaForetagsUppgifterErrorMessage] = useState('')
  const [reapplyingGrunduppgifter, setReapplyingGrunduppgifter] = useState(false)
  const [reactKey, setReactKey] = useState(0)
  const firstCellStyle = { paddingRight: '40px', minWidth: '225px' }
  const forceEditableYears = stringToBoolean(getUrlParam('editablefyears'))
  const FYearLockedTooltip = ({ children }) => (
    <RmTooltip
      visible={annualReport.grunduppgifter !== null && !forceEditableYears}
      title={
        'Datum för räkenskapsåret är hämtade direkt från Bolagsverket och går därför inte att ändra.' +
        (annualReport.financialYears.length === 1
          ? ' Notera att det första räkenskapsåret ofta är förkortat eller förlängt.'
          : '')
      }
    >
      {children}
    </RmTooltip>
  )
  FYearLockedTooltip.propTypes = { children: PropTypes.node }

  return (
    <React.Fragment>
      <h3>Företagsinformation</h3>
      <div>Organisationsnummer</div>
      <Box mt={1} />
      <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
        <RmTooltip title="Organisationsnumret går inte att ändra, vill du skapa en årsredovisning för ett annat företag så välj istället 'Ny årsredovisning' i menyn längst upp till höger.">
          <EditableOrganizationNumber
            className="automation-organization-number"
            defaultValue={annualReport.organizationNumber}
            disabled={true}
          />
        </RmTooltip>
        <div>
          <Button
            variant={'outlined'}
            onClick={async () => {
              setReapplyingGrunduppgifter(true)
              const { responseJson: result } = await API.reapplyGrunduppgifter(
                annualReport.reportId,
                abortSignal,
                async () => {
                  await onChange()
                  setReactKey(reactKey + 1)
                }
              )
              setReapplyingGrunduppgifter(false)
              if (!result.success) {
                setHamtaForetagsUppgifterErrorMessage(
                  `Företagsuppgifter saknas för organisationsnummer ${annualReport.organizationNumber}`
                )
              }
            }}
            disabled={isApproved}
          >
            Hämta företagsuppgifter
          </Button>
        </div>
        <div>{reapplyingGrunduppgifter && <CircularProgress size={26} />}</div>
      </div>
      <FlashMessage message={hamtaForetagsUppgifterErrorMessage} />
      <Box mt={4} />
      <div>Företagsnamn</div>
      <Box mt={1} />
      <RmTextField
        className="automation-company-name"
        defaultValue={annualReport.companyName}
        key={reactKey}
        onChange={(ev) => {
          API.saveAnnualReportValue(annualReport.reportId, 'companyName', ev.target.value, abortSignal, onChange)
        }}
        fullWidth={true}
        disabled={isApproved}
      />
      <Box mt={4} />
      <table>
        <tbody>
          <tr>
            <td style={firstCellStyle}>Nuvarande räkenskapsår</td>
            <td>
              <FYearLockedTooltip>
                <RmDatePicker
                  className={`automation-fyear-0-start`}
                  defaultValue={annualReport.financialYears[0].firstDay}
                  onChange={async (newDate) => {
                    await setFYear(annualReport.financialYears[0].financialYearId, { firstDay: newDate }, abortSignal)
                    onChange()
                  }}
                  disabled={isApproved || (annualReport.grunduppgifter !== null && !forceEditableYears)}
                />
              </FYearLockedTooltip>
            </td>
            <td>
              <FYearLockedTooltip>
                <RmDatePicker
                  className={`automation-fyear-0-end`}
                  defaultValue={annualReport.financialYears[0].lastDay}
                  onChange={async (newDate) => {
                    await setFYear(annualReport.financialYears[0].financialYearId, { lastDay: newDate }, abortSignal)
                    onChange()
                  }}
                  disabled={isApproved || (annualReport.grunduppgifter !== null && !forceEditableYears)}
                />
              </FYearLockedTooltip>
            </td>
          </tr>
          {annualReport.minimumFinancialYearCount < 4 &&
            (!annualReport.grunduppgifter || annualReport.grunduppgifter.rakenskapsperioder.length > 2) && (
              <tr>
                <td style={firstCellStyle}>Antal tidigare räkenskapsår</td>
                <td colSpan="2">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={String(annualReport.financialYears.length - 1)}
                    onChange={async (ev) => {
                      await setFYearCount(annualReport.reportId, Number(ev.target.value) + 1, abortSignal)
                      onChange()
                    }}
                    className="automation-fyear-count"
                  >
                    {annualReport.minimumFinancialYearCount <= 1 && (
                      <FormControlLabel
                        value="0"
                        control={<Radio color="primary" />}
                        label="0"
                        labelPlacement="top"
                        disabled={isApproved}
                      />
                    )}
                    {annualReport.minimumFinancialYearCount <= 2 && (
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="1"
                        labelPlacement="top"
                        disabled={isApproved}
                      />
                    )}
                    {annualReport.minimumFinancialYearCount <= 3 && (
                      <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label="2"
                        labelPlacement="top"
                        disabled={isApproved}
                      />
                    )}
                    <FormControlLabel
                      value="3"
                      control={<Radio color="primary" />}
                      label="3+"
                      labelPlacement="top"
                      disabled={isApproved}
                    />
                  </RadioGroup>
                </td>
              </tr>
            )}
          {annualReport.financialYears.slice(1).map((fYear, yearIdx) => {
            const yTitles = ['Förra räkenskapsåret', 'Räkenskapsåret dessförinnan', 'Räkenskapsåret dessförinnan']
            const yTitle = yTitles[yearIdx]
            return (
              <tr key={fYear.financialYearId}>
                <td style={firstCellStyle}>{yTitle}</td>
                <td key={fYear.financialYearId + 'firstDay'}>
                  <RmDatePicker
                    className={`automation-fyear-${yearIdx + 1}-start`}
                    defaultValue={fYear.firstDay}
                    onChange={async (newDate) => {
                      await setFYear(fYear.financialYearId, { firstDay: newDate }, abortSignal)
                      onChange()
                    }}
                    disabled={
                      isApproved || (yearIdx + 1 < annualReport.minimumFinancialYearCount && !forceEditableYears)
                    }
                  />
                </td>
                <td key={fYear.financialYearId + 'lastDay'}>
                  <RmDatePicker
                    className={`automation-fyear-${yearIdx + 1}-end`}
                    defaultValue={fYear.lastDay}
                    onChange={async (newDate) => {
                      await setFYear(fYear.financialYearId, { lastDay: newDate }, abortSignal)
                      onChange()
                    }}
                    disabled={
                      isApproved || (yearIdx + 1 < annualReport.minimumFinancialYearCount && !forceEditableYears)
                    }
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <h3>Företrädare</h3>
      <table>
        <thead>
          <tr>
            <td>Förnamn</td>
            <td>Efternamn</td>
            <td>Roll</td>
          </tr>
        </thead>
        <tbody>
          {annualReport.signatures.map((signature, idx) => (
            <Signature
              signature={signature}
              key={signature.foretradareId}
              onChange={onChange}
              disabled={isApproved}
              index={idx}
            />
          ))}
        </tbody>
      </table>
      <EditableInteger
        value={annualReport.signatures.length}
        onChange={async (newForetradareCount) => {
          await setForetradareCount(annualReport.reportId, newForetradareCount, abortSignal)
          onChange()
        }}
        disabled={isApproved}
      />
      {annualReport.revisionsberattelseKrav && (
        <React.Fragment>
          <Box mt={3} />
          <h3>Revisor</h3>
          <table>
            <thead>
              <tr>
                <td>Förnamn</td>
                <td>Efternamn</td>
                <td>Titel</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <RmTextField
                    className="automation-auditor-first-name"
                    defaultValue={annualReport.auditorFirstName}
                    key={reactKey}
                    onChange={(ev) => {
                      API.saveAnnualReportValue(
                        annualReport.reportId,
                        'auditorFirstName',
                        ev.target.value,
                        abortSignal,
                        onChange
                      )
                    }}
                    fullWidth={true}
                    disabled={isApproved}
                  />
                </td>
                <td>
                  <RmTextField
                    className="automation-auditor-last-name"
                    defaultValue={annualReport.auditorLastName}
                    key={reactKey}
                    onChange={(ev) => {
                      API.saveAnnualReportValue(
                        annualReport.reportId,
                        'auditorLastName',
                        ev.target.value,
                        abortSignal,
                        onChange
                      )
                    }}
                    fullWidth={true}
                    disabled={isApproved}
                  />
                </td>
                <td>
                  <RmComboBox
                    options={selectableAuditorRoles}
                    value={annualReport.auditorRole}
                    onChange={(newRole) => {
                      API.saveAnnualReportValue(annualReport.reportId, 'auditorRole', newRole, abortSignal, onChange)
                    }}
                    style={{ width: '280px' }}
                    disabled={isApproved}
                    className="automation-auditor-title"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Box mt={2} />
          <div>Revisionsbolag</div>
          <Box mt={1} />
          <RmTextField
            className="automation-auditor-company"
            defaultValue={annualReport.auditorCompany}
            onChange={(ev) => {
              API.saveAnnualReportValue(annualReport.reportId, 'auditorCompany', ev.target.value, abortSignal, onChange)
            }}
            disabled={isApproved}
            style={{ width: '708px' }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

ForetagsinformationEditor.propTypes = {
  annualReport: PropTypes.object,
  onChange: PropTypes.func,
  isApproved: PropTypes.bool.isRequired,
}

ForetagsinformationEditor.defaultProps = {
  onChange: () => {},
}

export default ForetagsinformationEditor
